<template>
  <div class="knowledge-base-add-component article">
    <div class="knowledge-base-add-component__header">
      <div
        @click="$store.commit('changeCreateMode', false)"
        class="knowledge-base-add-component__to-back"
      >
        <i class="icon-left"></i>
        <span>Назад</span>
      </div>
    </div>
    <Widget type="loader" v-if="loading" />
    <div v-show="!loading" class="knowledge-base-add-component__container">
      <Widget type="text-editor" :unique="'add-article-editor'" />
      <div class="knowledge-base-add-component__settings">
        <h3 class="knowledge-base-add-component__settings__title">Параметры</h3>
        <Widget
          :style="{ marginBottom: 20 + 'px' }"
          type="input-title"
          placeholder="Название"
          :unique="'article-add-name'"
          maxlength="100"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'ArticleAuthor'"
          type="select-new"
          :maxCount="1"
          placeholder="Автор"
          label="Автор"
          :editValue="articleAuthor"
        />
        <Widget
          :entity="'User'"
          :actionEntity="'getItems'"
          :itemName="'full_name'"
          :keyValue="'ArticleCo-Author'"
          type="select-new"
          :multiple="true"
          :maxCount="3"
          placeholder="Соавтор"
          label="Соавтор"
          :editValue="articleCoAuthors"
          :hiddenItems="articleAuthor"
        />
        <Widget
          :entity="'Category'"
          :actionEntity="'actionGetItems'"
          :itemName="'name'"
          :keyValue="'ArticleCategory'"
          type="select-new"
          :multiple="true"
          :maxCount="5"
          placeholder="Категории"
          label="Категории"
          :createMode="true"
        />
        <Widget
          :entity="'Tag'"
          :actionEntity="'actionGetItems'"
          :itemName="'name'"
          :keyValue="'ArticleTag'"
          type="select-new"
          :multiple="true"
          :maxCount="10"
          placeholder="Теги"
          label="Теги"
          :createMode="true"
        />
        <div class="knowledge-base-add-component__settings__files">
          <i class="icon-paper-clip"></i>
          <FileUploaderCalendar
            label="Прикрепить файл"
            acceptMore=".jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp4, .avi, .3gp, .mpeg, .mov, .mp3, .flv, .wmv"
            v-bind="{
              multiple: true,
              drop: false,
              dropDirectory: false,
              fileDrop: false,
              lengthText: 5,
              showOnlyFiles: true
            }"
            @changeFilesFromUploader="changeFilesFromUploader"
          />
        </div>
        <div class="knowledge-base-add-component__settings__links">
          <i class="icon-chain"></i>
          <Widget type="links-widget" :unique="'article-add-links'" />
        </div>
        <div class="knowledge-base-add-component__settings__cover">
          <FileUploaderCover
            v-bind="{
              multiple: false,
              drop: true,
              dropDirectory: true,
              fileDrop: true
            }"
            @clearParentPool="cover = ''"
            @changeCover="changeCover"
          />
        </div>
        <div class="knowledge-base-add-component__settings__buttons">
          <ModalButton
            :type="'cancel'"
            :title="'Отменить'"
            @handleButton="$root.$emit(`button:click_article-add-cancel`)"
          />
          <ModalButton
            :title="'Добавить'"
            @handleButton="$root.$emit(`button:click_article-add-create`)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadCoverMixin from "@/helpers/Files/uploadCover.mixin";
import uploadFilesMixin from "@/helpers/Files/uploadFiles.mixin";

import linksUploaderMixin from "@/components/Widgets/LinksWidget/linksUploader.mixin";
import axios from "axios";
import { domain } from "@/globalVariables";
const Widget = () => import("@/components/Widgets/Widget");
const FileUploaderCalendar = () =>
  import("@/components/Widgets/FileUploaderCalendar/FileUploaderCalendar");
const Textarea = () => import("@/components/Widgets/Textarea/Textarea");
const FileUploaderCover = () => import("@/views/KnowledgeBase/FileUploaderCover/FileUploaderCover");
import ModalButton from "@/components/Widgets/Button/ModalButton";
import httpClient from "@/api/client/httpClient";

export default {
  name: "ArticleAddComponent",
  mixins: [uploadFilesMixin, uploadCoverMixin],
  props: [],
  components: {
    FileUploaderCover,
    Textarea,
    FileUploaderCalendar,
    Widget,
    linksUploaderMixin,
    ModalButton
  },
  data() {
    return {
      loading: false,
      articleName: "",
      articleAuthor: [],
      articleCoAuthors: [],
      articleCategories: [],
      articleTags: [],
      articleLinks: [],
      content: "",
      preview: "",
      cover: ""
    };
  },
  beforeDestroy() {
    this.$root.$off("button:click_article-add-cancel");
    this.$root.$off("button:click_article-add-create");
  },
  mounted() {
    this.$root.$on("text-editor:change_add-article-editor", (value) => {
      this.content = value.value.replaceAll("&nbsp;", "").replaceAll("<br>", "").trim();
      this.preview = value.preview.replaceAll("&nbsp;", "").replaceAll("<br>", "").trim();
    });
    this.$root.$on("text:change_article-add-name", (value) => {
      this.articleName = value;
    });
    this.$root.$on("changeSelectValue:ArticleAuthor", (value) => {
      this.articleAuthor = value;
    });
    this.$root.$on("changeSelectValue:ArticleCo-Author", (value) => {
      this.articleCoAuthors = value;
    });
    this.$root.$on("changeSelectValue:ArticleCategory", (value) => {
      this.articleCategories = value;
    });
    this.$root.$on("changeSelectValue:ArticleTag", (value) => {
      this.articleTags = value;
    });
    this.$root.$on("links:change_article-add-links", (value) => {
      this.articleLinks = value;
    });
    this.$root.$on("button:click_article-add-cancel", () => {
      this.$store.commit("changeCreateMode", false);
    });
    this.$root.$on("button:click_article-add-create", () => {
      this.createChunksPoolFiles();
    });
    if (this.getDataInfoUser) {
      this.articleAuthor = [this.getDataInfoUser];
    }
  },
  methods: {
    async createChunksPoolFiles() {
      if (!this.checkValidationData()) return null;
      this.loading = true;
      if (!this.fileUploader.length && !this.coverUploader.length) {
        this.createArticleItem();
        return null;
      } else if (this.fileUploader.length && !this.coverUploader.length) {
        this.createPull(this.fileUploader);
      } else if (!this.fileUploader.length && this.coverUploader.length) {
        this.createPullCover(this.coverUploader);
      } else if (this.fileUploader.length && this.coverUploader.length) {
        this.createPull(this.fileUploader);
      }
    },
    createArticleItem() {
      httpClient({
        url: `/Article/actionCreate`,
        method: "POST",
        data: [
          {
            name: this.articleName,
            author_user_id: this.articleAuthor.length
              ? this.articleAuthor[0].id
              : this.getDataInfoUser.id,
            co_authors: this.articleCoAuthors.map((i) => {
              return i.id;
            }),
            categories: this.articleCategories.map((i) => {
              return i.id;
            }),
            tags: this.articleTags.map((i) => {
              return i.id;
            }),
            links: this.articleLinks,
            content: this.content,
            preview: this.preview,
            attachment_files: this.fileUploadingId,
            cover: this.cover
          }
        ]
      })
        .then(() => {
          this.fileUploadingId = [];
          this.fileUploader = [];
          this.loading = false;
          this.$store.commit("changeCreateMode", false);
          this.$emit("updateArticlesPage");
          this.$root.$emit("showNotification", {
            type: "success",
            timeout: 5000,
            label: "Статья успешно добавлена!"
          });
        })
        .catch((error) => {
          this.loading = false;
          this.errorAlert(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filesUploaded() {
      if (!this.coverUploader.length) {
        this.createArticleItem();
      } else {
        this.createPullCover(this.coverUploader);
      }
    },
    filesUploadedCover() {
      this.createArticleItem();
    },
    changeFilesFromUploader(files) {
      this.fileUploader = files;
    },
    checkValidationData() {
      if (!this.articleName.trim().length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Введите название статьи!"
        });
        return false;
      } else if (this.articleName.trim().length > 100) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Название статьи не может превышать 100 символов!"
        });
        return false;
      } else if (!this.articleAuthor.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Выберите автора!"
        });
        return false;
      } else if (!this.articleTags.length) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Должен быть выбран минимум 1 Тег!"
        });
      } else if (this.fileUploader.length > 10) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Максимальное кол-во файлов 10!"
        });
        return false;
      } else if (
        !this.content.replaceAll("&nbsp;", "").trim() ||
        !this.content.replaceAll("<p></p>", "").trim() ||
        !this.content.replaceAll("<p><br></p>", "").trim()
      ) {
        this.$root.$emit("showNotification", {
          type: "error",
          timeout: 5000,
          label: "Введите текст статьи!"
        });
        return false;
      } else {
        return true;
      }
    },
    changeCover(cover) {
      this.coverUploader = cover;
    }
  },
  computed: {
    getDataInfoUser() {
      return this.$store.getters.getDataInfoUser;
    }
  },
  watch: {},
  validations: {}
};
</script>

<style scoped lang="scss">
@import "~@/assets/base/color/index";
@import "~@/assets/base/color/colors.scss";
@import "~@/assets/base/breakpoints/breakpoints";

.knowledge-base-add-component {
  &--book {
    display: flex;
    justify-content: center;
  }

  &__bg {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .label {
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: $color-text-secondary;
  }

  &__header {
    margin-bottom: 17px;
  }

  &__to-back {
    display: flex;
    align-items: center;
    max-width: max-content;
    user-select: none;
    color: $color-interactive;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    transition: all 0.2s ease;
    cursor: pointer;

    .icon-left {
      margin-right: 5px;
      font-size: 20px;
    }

    &:hover {
      color: $color-button-hover;
    }

    &:active {
      color: $color-button-click;
    }
  }

  .loader-bg {
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 15%;
  }

  &__container {
    display: flex;
  }

  &__settings {
    width: 100%;
    height: 85vh;
    max-width: 480px;
    margin-left: 20px;
    padding: 29px 32px 20px 32px;
    overflow: scroll;
    border-radius: 24px;
    background-color: $color-white;

    &--book {
      max-width: 762px;
      height: 80vh;
    }

    &--bookedit {
      max-width: 762px;
      height: fit-content;
    }

    &__title {
      margin: 0 0 20px;
      font-weight: 500;
      color: $color-text-accent;
      font-size: 16px;
      line-height: 20px;
    }

    /deep/ {
      .select-bg {
        margin-bottom: 20px;
        width: 100%;

        .select-content-bg {
          height: 34px;
        }

        .form-group__textarea {
          height: 160px;
        }
      }
    }

    &__files {
      display: flex;
      margin-bottom: 20px;

      .icon-paper-clip {
        margin-right: 11px;
        font-size: 20px;
        color: $icon-subdued;
      }

      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        i {
          font-size: 20px;
          color: $icon-subdued;
          margin-right: 8px;
        }

        .uploader-label {
          font-size: 14px;
          line-height: 20px;
          color: $text-subdued;
          font-weight: 400;
        }

        .uploader-clear {
          margin-left: auto;
          color: $text-default;
          padding: 4px;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;

          cursor: pointer;

          &:hover {
            background: $action-secondary-hovered;
            border-radius: 4px;
          }
        }
      }
    }

    .knowledge-base-add-component__settings__wrap {
      width: 100%;
    }

    &__links {
      display: flex;
      margin-bottom: 20px;

      .icon-chain {
        font-size: 20px;
        color: $icon-subdued;
        margin: 3px 8px 0 0;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;

      @media screen and (max-width: $sm) {
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        margin-top: 20px;
      }

      .button-widget {
        margin-right: 15px;

        @media screen and (max-width: $sm) {
          width: 100%;
          order: 2;
          margin: 0;

          /deep/ {
            button {
              width: 100%;
            }
          }
        }

        &:last-child {
          margin-right: 0;

          @media screen and (max-width: $sm) {
            order: 1;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  /deep/ {
    .mark-select-bg {
      width: 100%;
      margin-bottom: 20px;

      .select-content-bg {
        height: 34px;

        .select-content {
          background: transparent;
        }
      }
    }
  }

  .tabs {
    margin-bottom: 20px;
  }

  &__job-folder {
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
      align-items: center;
      position: relative;

      .text {
        margin-right: 10px;
      }

      .icon-info {
        cursor: pointer;
        font-size: 12px;

        &:hover + .tooltip-reg {
          display: block;
        }
      }

      .tooltip-reg {
        display: none;
        position: absolute;
        top: -100px;
        left: 0;
        padding: 15px;
        z-index: 1000;

        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-white;

        background-color: $color-interactive;
        border-radius: 10px;
      }
    }
  }

  &__responsibilities {
    display: flex;
    flex-direction: column;

    .label {
      display: flex;
      align-items: center;
      position: relative;

      .text {
        margin-right: 10px;
      }

      .icon-info {
        cursor: pointer;
        font-size: 12px;

        &:hover + .tooltip-reg {
          display: block;
        }
      }

      .tooltip-reg {
        display: none;
        position: absolute;
        top: -80px;
        left: 0;
        padding: 15px;
        z-index: 1000;

        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: $color-white;

        background-color: $color-interactive;
        border-radius: 10px;
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .knowledge-base-add-component {
    &__container {
      flex-flow: column-reverse;
      align-items: center;
    }
    &__settings {
      max-width: 540px;
      height: 100%;
      margin-left: 0;
      margin-bottom: 40px;
    }
  }
}
</style>
